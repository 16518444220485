<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2" style="font-size: 17px">运维开发对比</span>
          <a v-on:click="oilClick" href="#"><span style="color: deepskyblue" >运维/</span></a>
          <a v-on:click="kfctricClick" href="#"><span style="color: #33cea0">开发</span></a>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" v-bind:key="config.data[0][2]"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['岗位', '薪资区间', '学历要求'],
        data: [
          ['特斯拉', '1', "大专"],

        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [50],
        align: ['center']
      }
    }
  },
  methods: {
    async kfctricClick() {
      const res = await this.$http.get('myApp/centerRightChange/0')
      this.$set(this.config, 'data', res.data.realData)

    },
    async oilClick() {
      const res = await this.$http.get('myApp/centerRightChange/1')
      this.$set(this.config, 'data', res.data.realData)


    }
  },
  async mounted() {
    const res=await  this.$http.get('myApp/centerRightChange/1')
    console.log(res)
    this.$set(this.config,'data',res.data.realData)

  }
}
</script>

<style lang="scss" scoped>
$box-height: 410px;
$box-width: 600px;
#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 600px;
      height: 340px;
    }
  }
}
</style>
