<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">大数据可视化平台</span>
              <dv-decoration-5
                class="dv-dec-6"
                :reverse="true"

              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text">广西人才网招聘数据</span>
            </div>
            <div class="react-left ml-3">

            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-3">
              <span class="text fw-b">用户：{{user}}</span>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-8>
                <centerRight1 />
              </dv-border-box-8>
            </div>
<!--            <div>-->
<!--&lt;!&ndash;              <dv-border-box-6>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                <centerLeft2 />&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;              </dv-border-box-6>&ndash;&gt;-->
<!--            </div>-->
            <!-- 中间 -->
            <dv-border-box-9>
              <center />
            </dv-border-box-9>
<!--            <div>-->
<!--              <center />-->
<!--            </div>-->
            <!-- 中间 -->
            <div>
              <dv-border-box-12>
              <centerLeft1 />
              </dv-border-box-12>
            </div>
            <dv-border-box-8 :reverse="true" style="width: 360px">
              <centerRight2 />
            </dv-border-box-8>
<!--            <div>-->
<!--                 <centerRight2 />-->
<!--            </div>-->
          </div>

          <!-- 第四行数据 -->
          <div class="bottom-box">
            <dv-border-box-13>
              <bottomRight />
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomLeft />

            </dv-border-box-12>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft1 from './centerLeft1'
// import centerLeft2 from './centerLeft2'
import centerRight1 from './centerRight1'
import centerRight2 from './centerRight2'
import center from './center'
import bottomLeft from './bottomLeft'
import bottomRight from './bottomRight'

export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      user:''
    }
  },
  components: {
    centerLeft1,
    // centerLeft2,
    centerRight1,
    centerRight2,
    center,
    bottomLeft,
    bottomRight
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

  },
  created() {
    // 在组件创建时读取 localStorage 中的 username
    this.user = localStorage.getItem('username');
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>
