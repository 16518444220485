<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-area" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2" style="font-size: 27px">公司排行榜</span>
          <div class="decoration2">
            <dv-decoration-2 :reverse="true" style="width:5px;height:6rem;" />
          </div>
        </div>
      </div>
     <div class="row_list">
       <ul class="job_rank" style="width: 100%;height: 420px;overflow: auto">
         <li style="font-size: 23px">
           <div>工资排名</div>
           <div>职位</div>
           <div>公司信息</div>
           <div>薪资区间</div>
           <div>招聘人数</div>
           <div>发布时间</div>
           <div>经验年限</div>

         </li>
         <li v-for="(job,index) in jobData" v-bind:key="job.title">
           <div>{{index+1}}</div>
           <div class="list_img"> <p>{{job.title}}</p></div>
           <div class="list_info">
             <p>{{job.companys}}</p>
           <p>{{job.nature}}/{{job.workplace}}</p>
           </div>
           <div class="list_price">{{job.salary}}</div>
           <div class="list_volume">{{job.people}}人</div>
           <div class="list_insure">{{job.times}}</div>
           <div class="list_market">{{job.years}}</div>


         </li>

       </ul>
     </div>
    </div>
  </div>
</template>

<script>
// import BottomRightChart from "@/components/echart/bottom/bottomRightChart";
export default {
  data(){
    return{
      jobData:''
    }
  },
  components: {
  },
  async mounted(){
    const res=await this.$http.get('myApp/bottomRight/')
    this.jobData=res.data.jobData
    console.log(this.jobData)
  }
};
</script>

<style lang="scss" class>
$box-height: 520px;
$box-width: 100%;
#bottomRight {
  padding: 14px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
  .row_list {
    list-style: none;
    .job_rank::-webkit-scrollbar{
      display: none;
    }
    .job_rank li{
      display: grid;
      -ms-grid-columns: 100px 150px 180px 120px 120px 110px 100px;
      grid-template-columns: 100px 150px 180px 120px 120px 110px 100px;
      cursor: pointer;
      margin-left: 23px;
      text-align: center;
      line-height: 30px;
    }
    .job_rank .list_index{
      font-size: 21px;
      line-height: 70px;
      font-weight: bold;

    }
    .job_rank .list_img{
      width: 156px;
      height: 80px;

    }
    .job_rank .list_price{
      line-height: 60px;

    }
    .job_rank .list_volume{
      line-height: 60px;
    }
    .job_rank .list_insure{
      line-height: 60px;
    }
    .job_rank .list_market{
      line-height: 60px;
    }



}
}

//列表

</style>